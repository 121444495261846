import { Container } from './hooks/useStyles'
import { useSvgPoints } from './hooks/useSvgPoints'

interface Props {
  region: uui.domain.client.rm.Region
}

export function RegionAvatar(props: Props) {
  const {
    region: { points, color },
  } = props

  const svgPoints = useSvgPoints(points)

  return (
    <Container color={color}>
      <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <polyline
          points={svgPoints}
          fill="#ffff"
          fillOpacity="0.4"
          stroke="#fff"
          strokeOpacity="1"
        />
      </svg>
    </Container>
  )
}
