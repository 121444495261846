import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { useMemo } from 'react'

import { intl } from '@/intl'
import { durationUtils } from '@/server-data'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const texts = useTexts()
  const { close, data } = useController()

  const formatSeconds = useMemo(() => durationUtils.formatSeconds(intl.translate), [])

  const title = data.issue === 'none' ? texts.headerTitle(data.orderStep.type) : ''

  const subTitle =
    data.issue === 'none'
      ? texts.headerSubTitle(
          data.pairedOrderStep.type,
          data.order.name,
          formatSeconds(data.pairedOrderStep.serviceTimeSec, 'LONG'),
        )
      : ''

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={title}
      subtitle={subTitle}
    />
  )
}
