import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'

// import { Export } from '@/icons'

import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    close,
    data: { selectedRoutes },
  } = useController()

  return (
    <ModalHeader
      title={texts.title(selectedRoutes.length)}
      // avatar={<Export size={16} />}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
    />
  )

  // return (
  //   <ModalHeader
  //     close={close}
  //     title={texts.title(selectedRoutes.length)}
  //     subTitle=""
  //     Icon={}
  //     hideCloseButton
  //     minHeight={74}
  //   />
  // )
}
