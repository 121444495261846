import { styled } from '@mui/material/styles'

interface ContainerProps {
  color: string
}

export const Container = styled('div')<ContainerProps>(({ theme, color }) => ({
  width: '40px',
  height: '40px',
  background: `#${color}`,
  border: `1px solid ${theme.palette.common.white}`,
  borderRadius: '50%',
  marginRight: '16px',
}))
